import React, { ChangeEvent, useEffect, useState } from 'react';
import { useAuth, useSignIn, useSignUp } from '@clerk/nextjs';
import { OAuthStrategy } from '@clerk/types';
import { NextPage } from 'next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import AuthCode from 'react-auth-code-input';
import toast from 'react-hot-toast';
import styles from './login.module.css';
interface ClerkError {
  errors: {
    message: string;
    code: string;
    longMessage: string;
  }[];
}

const LoginPage: NextPage = () => {
  const [verificationType, setVerificationType] = useState<'signIn' | 'signUp'>();
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const {
    signIn,
    isLoaded: isSignInLoaded,
    setActive: setSignInActive
  } = useSignIn();
  const {
    signUp,
    isLoaded: isSignUpLoaded,
    setActive: setSignUpActive
  } = useSignUp();
  const {
    isSignedIn,
    isLoaded
  } = useAuth();
  const {
    replace,
    query
  } = useRouter();
  const fromCollective = query?.source === 'collective';
  useEffect(() => {
    if (isSignedIn) {
      replace(`/callback${fromCollective ? '?source=collective&isNewUser=true' : ''}`);
    }
  }, [isSignedIn]);

  if (!isLoaded || isSignedIn) {
    return null;
  }

  const handleSocialLogin = (strategy: OAuthStrategy) => async () => {
    await signIn?.authenticateWithRedirect({
      strategy,
      redirectUrl: `/sso-callback${fromCollective ? '?source=collective&isNewUser=true' : ''}`,
      redirectUrlComplete: `/callback${fromCollective ? '?source=collective&isNewUser=true' : ''}`
    });
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleRequestCode = async () => {
    clearErrorMessage();
    if (!isSignInLoaded && !signIn || !isSignUpLoaded && !signUp) return null;

    try {
      // Start the Sign Up process using the email method
      await signIn.create({
        strategy: 'email_code',
        identifier: email
      });
      showSuccessMessage(`An email with the code has been sent to ${email}`);
      setVerificationType('signIn');
    } catch (err) {
      if ((err as ClerkError).errors[0].code === 'form_identifier_not_found') {
        try {
          await signUp.create({
            emailAddress: email
          });
          await signUp.prepareEmailAddressVerification();
          showSuccessMessage(`An email with the code has been sent to ${email}`);
          setVerificationType('signUp');
        } catch (err) {
          showErrorMessage((err as ClerkError).errors[0].longMessage);
        }
      } else {
        showErrorMessage((err as ClerkError).errors[0].longMessage);
      }
    }
  };

  const handleVerification = async () => {
    clearErrorMessage();
    if (!isSignInLoaded && !signIn || !isSignUpLoaded && !signUp) return null;

    try {
      const completedFlow = verificationType === 'signIn' ? await signIn.attemptFirstFactor({
        strategy: 'email_code',
        code: verificationCode
      }) : await signUp.attemptEmailAddressVerification({
        code: verificationCode
      }); // This mainly for debugging while developing.
      // Once your Instance is setup this should not be required.

      if (completedFlow.status !== 'complete') {
        console.error(JSON.stringify(completedFlow, null, 2));
      } // If verification was completed, create a session for the user


      if (completedFlow.status === 'complete') {
        const sessionId = completedFlow.createdSessionId;

        if (verificationType === 'signIn') {
          await setSignInActive({
            session: sessionId
          });
        } else {
          await setSignUpActive({
            session: sessionId
          });
        } // Redirect user


        replace(`/callback${fromCollective ? '?source=collective&isNewUser=true' : ''}`);
      }
    } catch (err) {
      showErrorMessage((err as ClerkError).errors[0].longMessage);
    }
  };

  const showSuccessMessage = (message: string) => {
    toast.success(message);
  };

  const showErrorMessage = (err: string) => {
    toast.error(err);
  };

  const clearErrorMessage = () => {
    toast.remove();
  };

  return <div className={styles['container']}>
      <div className={`${styles['root']} ${styles['grid']}`}>
        <div className={styles['hero']}>
          <img alt='28' className={styles['logo-header']} src='https://28.co/images/logo/gradient.svg' width='39' />
          <video autoPlay className={styles['video']} loop muted playsInline poster='https://28.co/video/28-video-vertical-poster.jpg' preload='auto'>
            <source src='https://stream.mux.com/apRVVETLXL01wXO3itROCThBNtYUEceATgLM3kEvyr5I/high.mp4' type='video/mp4' />
            <p>Your browser does not support the video tag.</p>
          </video>
        </div>
        <div className={styles['content-wrapper']}>
          <div className={styles['content']}>
            <img alt='28' className={styles['logo-sidebar']} src='https://28.co/images/logo/gradient.svg' width='64' />
            <div className={styles['login-form']}>
              <button className={`${styles['btn-base']} ${styles['btn-social-login']}`} data-connection='google-oauth2' id='social-login-btn-google' onClick={handleSocialLogin('oauth_google')} type='button'>
                <svg fill='none' height='21' viewBox='0 0 20 21' width='20' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M19.7875 10.2264C19.7875 9.56805 19.7292 8.94305 19.6292 8.33472H10.2125V12.0931H15.6042C15.3625 13.3264 14.6542 14.3681 13.6042 15.0764V17.5764H16.8209C18.7042 15.8347 19.7875 13.2681 19.7875 10.2264Z' fill='#4285F4' />
                  <path d='M10.2125 20.0016C12.9125 20.0016 15.1708 19.1016 16.8208 17.5766L13.6041 15.0766C12.7041 15.6766 11.5625 16.0432 10.2125 16.0432C7.60414 16.0432 5.39581 14.2849 4.60414 11.9099H1.28748V14.4849C2.92914 17.7516 6.30414 20.0016 10.2125 20.0016Z' fill='#34A853' />
                  <path d='M4.60419 11.9097C4.39586 11.3097 4.28752 10.6681 4.28752 10.0014C4.28752 9.33473 4.40419 8.69307 4.60419 8.09307V5.51807H1.28752C0.60419 6.86807 0.212524 8.38473 0.212524 10.0014C0.212524 11.6181 0.60419 13.1347 1.28752 14.4847L4.60419 11.9097Z' fill='#FBBC05' />
                  <path d='M10.2125 3.9598C11.6875 3.9598 13.0041 4.46813 14.0458 5.4598L16.8958 2.6098C15.1708 0.993132 12.9125 0.00146484 10.2125 0.00146484C6.30414 0.00146484 2.92914 2.25147 1.28748 5.51813L4.60414 8.09313C5.39581 5.71813 7.60414 3.9598 10.2125 3.9598Z' fill='#EA4335' />
                </svg>
                Continue with Google
              </button>
              <button className={`${styles['btn-base']} ${styles['btn-social-login']}`} data-connection='apple' id='social-login-btn-apple' onClick={handleSocialLogin('oauth_apple')} type='button'>
                <svg fill='none' height='20' viewBox='0 0 20 20' width='20' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M10.9958 1.74087C12.3098 0.00893178 14.1366 0.000488281 14.1366 0.000488281C14.1366 0.000488281 14.4084 1.6288 13.1029 3.19739C11.7091 4.87229 10.1247 4.59822 10.1247 4.59822C10.1247 4.59822 9.82725 3.28097 10.9958 1.74087V1.74087ZM10.2919 5.73885C10.9679 5.73885 12.2225 4.8096 13.8556 4.8096C16.6666 4.8096 17.7725 6.80986 17.7725 6.80986C17.7725 6.80986 15.6096 7.91569 15.6096 10.599C15.6096 13.6259 18.304 14.6691 18.304 14.6691C18.304 14.6691 16.4205 19.9703 13.8765 19.9703C12.7081 19.9703 11.7996 19.1829 10.5685 19.1829C9.31387 19.1829 8.06884 19.9997 7.25793 19.9997C4.93485 19.9998 2 14.971 2 10.9287C2 6.95162 4.48416 4.86532 6.81421 4.86532C8.32894 4.86532 9.5044 5.73885 10.2919 5.73885V5.73885Z' fill='#1D1D1F' />
                </svg>
                Continue with Apple
              </button>
              <button className={`${styles['btn-base']} ${styles['btn-social-login']}`} data-connection='facebook' id='social-login-btn-facebook' onClick={handleSocialLogin('oauth_facebook')} type='button'>
                <svg fill='none' height='21' viewBox='0 0 20 21' width='20' xmlns='http://www.w3.org/2000/svg'>
                  <path clipRule='evenodd' d='M8.43776 19.8815V12.8942H5.89803V10.0029H8.43776V7.79931C8.43776 5.29193 9.93122 3.90816 12.215 3.90816C13.3091 3.90816 14.4538 4.10363 14.4538 4.10363V6.56461H13.1924C11.9492 6.56461 11.5625 7.33665 11.5625 8.12697V10.0029H14.3357L13.8927 12.8942H11.5625V19.8815C16.3432 19.1314 20 14.9942 20 10.0029C20 4.48007 15.5228 0.00292969 10 0.00292969C4.47715 0.00292969 0 4.48007 0 10.0029C0 14.9943 3.65696 19.1315 8.43776 19.8815ZM10.329 19.9975C10.6398 19.9875 10.9471 19.9633 11.2501 19.9255C10.9471 19.963 10.6399 19.9874 10.329 19.9975Z' fill='#1977F3' fillRule='evenodd' />
                </svg>
                Continue with Facebook
              </button>
              <div className={styles['or-hr']}>
                <span>Or</span>
              </div>
            </div>
            {!verificationType ? <div className={styles['login-form']} style={{
            gap: 0
          }}>
                <input aria-label='Your Email' aria-placeholder='Email Address' className={styles['input-base']} name='email' onChange={handleEmailChange} placeholder='Email Address' required />
                <button className={styles['btn-base']} id='login_submit' onClick={handleRequestCode} type='submit'>
                  Continue
                </button>
                <div className={`${styles['terms']} ${styles['small-text']}`}>
                  By signing up, you agree to our{' '}
                  <a className={styles['link']} href='https://28.co/terms' rel='noreferrer' target='_blank'>
                    terms of service
                  </a>{' '}
                  and{' '}
                  <a className={styles['link']} href='https://28.co/privacy' rel='noreferrer' target='_blank'>
                    privacy policy
                  </a>
                  .
                </div>
              </div> : <div className={styles['otp-form']}>
                <label className={styles['code']}>Enter your verification code</label>
                <AuthCode allowedCharacters='numeric' autoFocus containerClassName={styles['otp-input']} inputClassName={styles['input-base']} onChange={c => {
              setVerificationCode(c);

              if (c.length === 6) {
                const otpConfirm = document.getElementById('btn-otp-confirm');
                otpConfirm?.focus();
              }
            }} />
                <button className={`${styles['btn-base']} ${styles['btn-otp-confirm']}`} id='btn-otp-confirm' onClick={handleVerification} type='submit'>
                  Confirm
                </button>
              </div>}
          </div>
        </div>
      </div>
    </div>;
};

export default dynamic(() => Promise.resolve(LoginPage), {
  ssr: false
});